import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import {
    Container,
    CssBaseline,
    ListItemIcon,
} from '@material-ui/core';

import {
    Check,
    Clear,
    SaveAlt,
    FilterList,
    Work,
    People,
    FirstPage,
    LastPage,
    Receipt,
    ChevronRight,
    ChevronLeft,
    Search,
    Remove,
    ViewColumn,
} from '@material-ui/icons';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import DeleteIcon from '@material-ui/icons/Delete';
import Warning from "@material-ui/icons/Warning";

import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardIcon from "../components/Card/CardIcon.js";
import CardBody from "../components/Card/CardBody.js";
import CardFooter from "../components/Card/CardFooter.js";
import Loading from '../components/Loading';
import Nav from '../components/NavBar/Nav';

import api from '../services/Api';


class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchNodes: "",
            token: '',
            drawer: false,
            loading: false,
            columns: [
                {title: 'Id', field: 'id'},
                {title: 'Serviço Trampare', field: 'name'},
                {title: 'Cadastrado Em', field: 'created_at'},
                {title: 'Ativo', field: 'active'},
            ],
            data: [
                {
                    id: '1',
                    name: 'Auto Elétrica',
                    created_at: 'XX/XX/XX HH:mm:ss',
                    active: 'Sim',
                },
                {
                    id: '2',
                    name: 'Manicure',
                    created_at: 'XX/XX/XX HH:mm:ss',
                    active: 'Sim',
                },
                {
                    id: '3',
                    name: 'Ar Condicionado',
                    created_at: 'XX/XX/XX HH:mm:ss',
                    active: 'Sim',
                },
                {
                    id: '4',
                    name: 'Calhas e Rufos',
                    created_at: 'XX/XX/XX HH:mm:ss',
                    active: 'Sim',
                },
            ]
        };
    }

    componentDidMount() {

        this.setState({ loading: true });

        const token = localStorage.getItem('TOKEN_KEY');

        api.post("/admin/check", { }, { headers: { Authorization: 'Bearer ' + token }})
        .then(
            res => {
                if(res.data.status){
                    setTimeout(() => this.setState({ loading: false }), 1000);                 
                }

                if(!res.data.status){
                    setTimeout(() => this.setState({ loading: false }), 1000);                
                }
            }
        ).catch(err => {
            console.log(err);
        })     
    }

    render() {

    if(this.state.loading) {
        return <Loading />;           
    }

    return (
      <div className={styles.root}>
          <CssBaseline/>
          <Nav />

          <Container maxWidth="lg" style={dashboard.table}>
         
          <Grid container justify="center">
            <Grid
              spacing={4}
              alignItems="center"
              justify="center"
              container
              className={dashboard.grid}
            >
              
            <Grid item xs={12} md={4}>
                <Card style={dashboard.card}>
                    <CardHeader color="warning" stats icon>
                        <CardIcon color="success">
                            <ListItemIcon>
                                <Receipt/>
                            </ListItemIcon>
                        </CardIcon>
                        <div style={dashboard.card_header}>
                            <p>Não Lidas</p>
                            <h3 >
                                8/50 <small>.</small>
                            </h3>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <h2 style={dashboard.card_title}>Reclamações</h2>
                    </CardBody>
                    <CardFooter stats>
                        <div >
                            <Warning />
                            <Link to={'/complaints'} style={dashboard.card_link}>                           
                                Gerenciar
                            </Link>
                        </div>
                    </CardFooter>
                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card style={dashboard.card}>
                    <CardHeader color="warning" stats icon>
                        <CardIcon color="warning">
                            <ListItemIcon>
                                <People/>
                            </ListItemIcon>
                        </CardIcon>
                        <div style={dashboard.card_header}>
                            <p>Novos Usuários</p>
                            <h3 >
                                49/5500 <small>pessoas</small>
                            </h3>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <h2 style={dashboard.card_title}>Usuários</h2>
                    </CardBody>
                    <CardFooter stats>
                        <div >
                            <Warning />
                            <Link to={'/users'} style={dashboard.card_link}>                           
                                Gerenciar
                            </Link>
                        </div>
                    </CardFooter>
                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card style={dashboard.card}>
                    <CardHeader color="warning" stats icon>
                        <CardIcon color="danger">
                            <ListItemIcon>
                                <Work/>
                            </ListItemIcon>
                        </CardIcon>
                        <div style={dashboard.card_header}>
                            <p>Novos Profissionais</p>
                            <h3 >
                                8/50 <small>não aprovados</small>
                            </h3>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <h2 style={dashboard.card_title}>Profissionais</h2>
                    </CardBody>
                    <CardFooter stats>
                        <div >
                            <Warning />
                            <Link to={'/professionals'} style={dashboard.card_link}>                           
                                Gerenciar
                            </Link>
                        </div>
                    </CardFooter>
                </Card>
            </Grid>

            </Grid>           
        </Grid>  

              <MaterialTable 
                  icons={{
                      Check: () => <Check/>,
                      Clear: () => <Clear/>,
                      Export: () => <SaveAlt/>,
                      Filter: () => <FilterList/>,
                      FirstPage: () => <FirstPage/>,
                      LastPage: () => <LastPage/>,
                      NextPage: () => <ChevronRight/>,
                      PreviousPage: () => <ChevronLeft/>,
                      Search: () => <Search/>,
                      ResetSearch: () => <Clear/>,
                      ThirdStateCheck: () => <Remove/>,
                      ViewColumn: () => <ViewColumn/>,
                      DetailPanel: () => <ChevronRight/>
                  }}

                  style={dashboard.datatable}
                  
                  title="Serviços"
                  columns={this.state.columns}
                  data={this.state.data}
                  actions={[
                      {
                        icon: () => <RemoveRedEyeIcon/>,
                        tooltip: 'Visualizar',
                        onClick: (event, rowData) => alert("Visualizar informações de " + rowData.name)
                      },
                      rowData => ({
                        icon: () => <DeleteIcon/>,
                        tooltip: 'Deletar Usuário',
                        onClick: (event, rowData) => alert("Você deletou o " + rowData.name),
                        disabled: rowData.birthYear < 2000
                      })
                    ]}
                    options={{
                      actionsColumnIndex: -1
                    }}
              />
        
          </Container>

      </div>
  )
  }

}

const dashboard = {
    menu: {
        width: 250,
    },
    table: {
        marginTop: 50,
        display: 'flex',
        
        flexWrap: 'wrap',
        
    },
    datatable: {
      width: '100%',
      marginBottom: 50,
    },
    card: {  
 
    },
    card_title: {  
        textAlign: 'center',
        color: '#777',  
    },
    card_header: {  
        color: '#999',  
    },
    card_link: {  
        textDecoration: 'none',
        color: '#777',   
    },
    menu_left: {
        position: 'absolute',
        right: 20
    },
    drawer: {
        backgroundColor: '#333333'
    },
    grid: {
        margin: 10,
    },
}


const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    display: 'flex',
  },
  drawer: {
    width: 'auto',
  },
  list: {
    width: 250,
  },
});

export default withStyles(styles)(Dashboard);
