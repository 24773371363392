import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {Container} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

class CreateHeader extends Component {
  state = { redirect: false }

  backToServices = () => {
    this.setState({
      redirect: true
    })
  }

  render() {
    if(this.state.redirect) {
      return <Redirect to='/services' />;           
    }

    return ( 
      <Container maxWidth="md" style={style.header}>
        <h2>{this.props.title}</h2>
          <Button
            style={style.headerBtn}
            variant="contained"
            startIcon={<ArrowBackIcon />}
            size="small"
            onClick={this.backToServices}
          >
            Voltar
          </Button>
      </Container>
    );
  }
}

const style = {

  header: {
    marginTop: 50,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
  },

  headerBtn: {
    height: 36,
    color: '#fff',
    backgroundColor: '#ff261b'
  },
}

export default CreateHeader;