import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Container,
    CssBaseline,
} from '@material-ui/core';
import {
    Check,
    Clear,
    SaveAlt,
    FilterList,
    FirstPage,
    LastPage,
    ChevronRight,
    ChevronLeft,
    Search,
    Remove,
    ViewColumn,
} from '@material-ui/icons';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import DeleteIcon from '@material-ui/icons/Delete';

import MaterialTable from 'material-table';
import Loading from '../../components/Loading';
import Nav from '../../components/NavBar/Nav';

import api from '../../services/Api';
import ScreenHeader from '../../components/ScreenHeader';
  

class Services extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            columns: [
                {title: 'Id', field: 'id'},
                {title: 'Nome do Serviço', field: 'name'},
                {title: 'Cadastrado Em', field: 'created_at'},
                {title: 'Ativo', field: 'active'},
            ],
            data: [
                {
                    id: '1',
                    name: 'Auto Elétrica',
                    created_at: 'XX/XX/XX HH:mm:ss',
                    active: 'Sim',
                },
                {
                    id: '2',
                    name: 'Manicure',
                    created_at: 'XX/XX/XX HH:mm:ss',
                    active: 'Sim',
                },
                {
                    id: '3',
                    name: 'Ar Condicionado',
                    created_at: 'XX/XX/XX HH:mm:ss',
                    active: 'Sim',
                },
                {
                    id: '4',
                    name: 'Calhas e Rufos',
                    created_at: 'XX/XX/XX HH:mm:ss',
                    active: 'Sim',
                },
            ]
        };
    }

    componentDidMount() {

        this.setState({ loading: true });

        const token = localStorage.getItem('TOKEN_KEY');

        api.post("/admin/check", { }, { headers: { Authorization: 'Bearer ' + token }})
        .then(
            res => {
                if(res.data.status){
                    setTimeout(() => this.setState({ loading: false }), 1000);                 
                }

                if(!res.data.status){
                    setTimeout(() => this.setState({ loading: false }), 1000);                
                }
            }
        ).catch(err => {
            console.log(err);
        })     
    }

    render() {

        if(this.state.loading) {
            return <Loading />;           
        }

        return (
            <div className={styles.root}>
                <CssBaseline/>
                <Nav />

                <Container maxWidth="lg" style={services.table}>
                    <ScreenHeader title="Serviços" btnTitle="Adicionar" route="/create" />
                    
                
                    <MaterialTable 
                        icons={{
                            Check: () => <Check/>,
                            Clear: () => <Clear/>,
                            Export: () => <SaveAlt/>,
                            Filter: () => <FilterList/>,
                            FirstPage: () => <FirstPage/>,
                            LastPage: () => <LastPage/>,
                            NextPage: () => <ChevronRight/>,
                            PreviousPage: () => <ChevronLeft/>,
                            Search: () => <Search/>,
                            ResetSearch: () => <Clear/>,
                            ThirdStateCheck: () => <Remove/>,
                            ViewColumn: () => <ViewColumn/>,
                            DetailPanel: () => <ChevronRight/>
                        }}
                        
                        title=""
                        columns={this.state.columns}
                        data={this.state.data}
                        actions={[
                            {
                              icon: () => <RemoveRedEyeIcon/>,
                              tooltip: 'Visualizar',
                              onClick: (event, rowData) => alert("Visualizar informações de " + rowData.name)
                            },
                            rowData => ({
                              icon: () => <DeleteIcon/>,
                              tooltip: 'Deletar Usuário',
                              onClick: (event, rowData) => alert("Você deletou o " + rowData.name),
                              disabled: rowData.birthYear < 2000
                            })
                          ]}
                          options={{
                            actionsColumnIndex: -1
                          }}
                    />
                </Container>

            </div>
        )
    }

}

const services = {

    table: {
        marginTop: 50,
    },
}

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
    },
    root: {
        display: 'flex',
    },
    drawer: {
        width: 'auto',
    },
    list: {
        width: 250,
    },
});


export default withStyles(styles, { withTheme: true })(Services);

