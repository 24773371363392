import React, { Component } from 'react';

import Topbar from './Topbar';
import Sidebar from './Sidebar';

class Nav extends Component {
  state = {
    drawer: false
  }

  openDrawer = () => {
    this.setState({drawer: !this.state.drawer})
  }

  render() { 
    return (
      <React.Fragment>
      
        <Topbar openDrawer={this.openDrawer} />

        <Sidebar openDrawer={this.openDrawer} drawer={this.state.drawer}/>
    
      </React.Fragment>
    );
  }
}
 
export default Nav;
