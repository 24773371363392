import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

class Toast extends React.Component {

  render() {
    return(
      <Snackbar
        anchorOrigin={{"horizontal":"right" ,"vertical":"bottom"}}
        autoHideDuration={2000}
        open={this.props.open}
      >
        <SnackbarContent style={{
            backgroundColor:'#29ABE2',
            fontSize: 16,
          }}
          message={this.props.msg}
        />
      </Snackbar>                   
    );
  }

}

export default Toast;