import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@material-ui/core';
import {
  People,
  Work,
  Receipt,
  Home,
} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import logo from '../../assets/img/logotipo-trampare.png';


class Sidebar extends Component {
  state = {}

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() { 
    return (
      // <SwipeableDrawer anchor="left" open={this.state.drawer} onClose={this.openDrawer} style={sidebar.drawer}>
      <SwipeableDrawer anchor="left" open={this.props.drawer} onClose={this.props.openDrawer.bind(this)} style={sidebar.drawer}>
        <div style={sidebar.sidebar}>
          <List component="sidebar" aria-label="main mailbox folders">

            <ListItem style={sidebar.img}>
              <img src={logo} style={{ width: 140 }} alt="Trampare Logo" />
            </ListItem>

            <Divider />

            <ListItem button>
              <ListItemIcon>
                <Home/>
              </ListItemIcon>
              <Link to={'/dashboard'} style={sidebar.links}>
                <ListItemText primary="Dashboard"/>
              </Link>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <SettingsApplicationsIcon/>
              </ListItemIcon>
              <Link to={'/services'} style={sidebar.links} >
                <ListItemText primary="Serviços"/>
              </Link>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <Receipt/>
              </ListItemIcon>
              <Link to={'/complaints'} style={sidebar.links} >
                <ListItemText primary="Reclamações"/>
              </Link>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <People/>
              </ListItemIcon>
              <Link to={'/users'} style={sidebar.links} >
                <ListItemText primary="Usuários"/>
              </Link>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <Work/>
              </ListItemIcon>
              <Link to={'/professionals'} style={sidebar.links} >
                <ListItemText primary="Profissionais"/>
              </Link>
            </ListItem>

          </List>
          <div>
            <Divider />
            <ListItem button>
              <ListItemIcon>
                <LiveHelpIcon/>
              </ListItemIcon>
              <Link onClick={this.handleClickOpen} style={sidebar.links} >
                <ListItemText primary="Ajuda"/>
              </Link>
            </ListItem>

            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Precisa de ajuda?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Entre em contato para entendermos melhor o seu problema.
                  <br />
                  <br />                           
                  Contato do suporte.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  FECHAR
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }
}

const sidebar = {
  sidebar: {
    width: 250,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  img: {
    justifyContent: 'center',
  },
  links: {
    textDecoration: 'none',
    width: '100%',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  drawer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  }
}

export default Sidebar;


        