import React from 'react';
import ReactDOM from 'react-dom';
import './screens/css/index.css';
import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import Users from './screens/Users';
import Professionals from './screens/Professionals';
import Complaints from './screens/Complaints';
import Services from './screens/Service/Services';
import Create from './screens/Service/Create';
import * as serviceWorker from './serviceWorker';

import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';

const palette = {
    primary: {
        light: '#333333',
        main: '#333333',
        dark: '#333333',
        contrastText: '#fff',
    },
    secondary: {
        light: '#fbb03b',
        main: '#fbb03b',
        dark: '#fbb03b',
        contrastText: '#fff',
    }
};

const theme = createMuiTheme({palette});

ReactDOM.render(
    
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact={true} component={Login}/>
                    <Route path="/dashboard" component={Dashboard}/>
                    <Route path="/users" component={Users}/>
                    <Route path="/professionals" component={Professionals}/>
                    <Route path="/complaints" component={Complaints}/>
                    <Route path="/services" component={Services}/>
                    <Route path="/create" exact={true} component={Create}/>
                </Switch>
            </ BrowserRouter>
        </ThemeProvider>,
    
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
