import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import {
  AppBar,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  AccountCircle,
  Menu,
} from '@material-ui/icons';

import logoWhite from '../../assets/img/logotipo-white.png'

class Topbar extends Component {
  state = { redirect: false }

  logout = () => {
    this.setState({
      redirect: true
    })
    localStorage.setItem('KeepLogged', false);
    localStorage.setItem('TOKEN_KEY', '-');
    localStorage.setItem('REFRESH_TOKEN', '-');
  }

  render() {
    if(this.state.redirect) {
      return <Redirect to='/' />;           
    }

    return ( 
      <AppBar position="static">             
        <Toolbar style={topbar.bar}>
            <IconButton edge="start" color="inherit" aria-label="menu">
                <Menu onClick={this.props.openDrawer.bind(this)}/>
            </IconButton>
            <Link to={'/dashboard'}>
              <img src={logoWhite} style={topbar.bar_logo} alt="Trampare Logo" />
            </Link>
            <div style={topbar.menu_left}>
                <IconButton aria-label="Perfil" color="inherit" onClick={this.logout} >
                    <ExitToAppIcon/>
                </IconButton>
            </div>
        </Toolbar>
      </AppBar>
    );
  }
}

const topbar = {
  menu: {
      width: 250,
  },
  bar: {
    backgroundColor: '#072672',
    alignItems: 'center',
  },
  bar_logo: {
    width: 110, 
    marginLeft: 20,
  },
  menu_left: {
      position: 'absolute',
      right: 20
  },
}

export default Topbar;