import React from 'react';
import './css/App.css';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Redirect } from 'react-router-dom';
import api from '../services/Api';
import Loading from '../components/Loading';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            open: false,
            loading: false,
            redirect: false,
            remember: false,
            msg: 'Seja vem vindo(a)!',
            color: '#29ABE2',
        };
    }

    login = () => {
        this.setState({
          redirect: true
        })
    }

    handleKeepLogged = () => {
        localStorage.setItem('KeepLogged', !this.state.remember);
        this.setState({ remember: !this.state.remember });
    }

    handleClose = (e) => {
		this.setState({ open: false });
	}

    openSnack = (msg, color) => {
        this.setState({ open: true, msg: msg, color: color });
    };

    componentDidMount() {

        if ((localStorage.getItem("TOKEN_KEY") !== null) && (localStorage.getItem('KeepLogged') === "true")) {

            this.setState({ loading: true });
          
            const token = localStorage.getItem('TOKEN_KEY');

            api.post("/admin/check", { }, { headers: { Authorization: 'Bearer ' + token }})
            .then(
                res => {
                    if(res.data.status){
                        setTimeout(() => this.setState({ loading: false }), 1000);
                        this.props.history.push("/dashboard");                 
                    }
    
                    if(!res.data.status){
                        setTimeout(() => this.setState({ loading: false }), 1000);
                        this.setState({ loading: false });                
                    }
                }
            )
            
        }

        this.setState({ open: true });
    }
    
    submitLogin = (e) => {
        e.preventDefault();

        const { email, password } = this.state;

        api.post("/admin/login", { email, password })
        .then(
            res => {
            
                if(res.status) {
                    const token = res.data.token.token
                    const refreshToken = res.data.token.refreshToken
                    const expiresIn = res.data.expiresIn;

                    localStorage.setItem('TOKEN_KEY', token);
                    localStorage.setItem('REFRESH_TOKEN', refreshToken);
                    localStorage.setItem('EXPIRES_IN', expiresIn);
                    this.props.history.push("/dashboard")
                } else {
                    this.openSnack('Login incorreto, tente novamente.', '#D12424')
                }
            }
        )
        .catch(
            err => {this.openSnack('Algo deu errado', '#D12424')}
        )           
    }

  
    render() {

        if(this.state.loading) {
            return <Loading />;           
        }

        if(this.state.redirect) {
            return <Redirect to='/dashboard' />;           
        }

        const {classes} = this.props;
   
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline/>
                
                <Snackbar
                    anchorOrigin={{"horizontal":"right" ,"vertical":"bottom"}}
                    autoHideDuration={6000}
                    open={this.state.open}
                    onClose={this.handleClose}
                >
                <SnackbarContent style={{
                    backgroundColor: this.state.color,
                    fontSize: 16,
                    justifyContent: 'center',
                  }}
                  message={this.state.msg}
                />
                </Snackbar>
                
                <Grid item xs={false} sm={4} md={7} className={classes.image}/>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Entre com suas credenciais
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={this.submitLogin}>
                            <TextField
                                onChange={(event) => this.setState({email: event.target.value})}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                onChange={(event) => this.setState({password: event.target.value})}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary"/>}
                                label="Manter logado"
                                onChange={this.handleKeepLogged}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Entrar
                            </Button>

                        </form>
                    </div>
                </Grid>
            </Grid>
        );
    }

}

const styles = {
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url("bg.png")',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#EEF',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        marginTop: 100,
        marginLeft: 50,
        marginRight: 50,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: 20,
        backgroundColor: '#29ABE2',
    },
    form: {
        width: '100%', 
        marginTop: 20,
        padding: 20
    },
    submit: {
        marginTop: 10,
        backgroundColor: '#072672',
    },
};

export default withStyles(styles)(Login);