import React from 'react';

import {Container} from '@material-ui/core';
import {CssBaseline} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import SaveIcon from '@material-ui/icons/Save';

import Nav from '../../components/NavBar/Nav';
import CreateHeader from '../../components/CreateHeader';

export default function Create() {
  
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (

    <div className={create.root}>
      <CssBaseline/> 
      <Nav />
      <CreateHeader title="Adicionar Serviço" />

      <Container maxWidth="md" style={create.content}>
        <form onSubmit={() => { console.log('submit') }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                id="firstName"
                name="firstName"
                label="Nome do Serviço"
                fullWidth
                autoComplete="given-name"
              />
            </Grid>
        
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="standard-adornment-amount">Ícone APP ( 500px / 500px Png Transparente)</InputLabel>
              <Input
                required
                name="service_icon"
                type="file"
                value={values.amount}
                onChange={handleChange('amount')}
                fullWidth 
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="standard-adornment-amount">Background APP (1080/1920 Jpg Otimizado para Web)</InputLabel>
              <Input
                required
                name="service_img"
                type="file"
                value={values.amount}
                onChange={handleChange('amount')}
                fullWidth 
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                style={create.btnSubmit}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Salvar
              </Button>
            </Grid>

          </Grid>
        </form>
      </Container>
    </div>
  );
}

const create = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%'
  },

  content: {
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 50,
    width: '100%',
    display: 'flex',
    backgroundColor: '#fff',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',   
  },

  btnSubmit: {
    color: '#fff',
    backgroundColor: '#29ABE2'
  }
}

