import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {Container} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';

class CreateHeader extends Component {
  state = { redirect: false }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.props.route} />
    }
  }

  render() {
    return ( 
      <Container maxWidth="lg" style={style.header}>
        <h2>{this.props.title}</h2>
        {this.renderRedirect()}
          <Button
            style={style.headerBtn}
            variant="contained"
            startIcon={<Add />}
            size="small"
            onClick={this.setRedirect}
          >
            {this.props.btnTitle}
          </Button>
      </Container>
    );
  }
}

const style = {

  header: {
    marginTop: 50,
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
  },

  headerBtn: {
    height: 36,
    color: '#fff',
    backgroundColor: '#072672'
  },
}

export default CreateHeader;