import { create } from 'apisauce';
import { parseISO, isBefore } from 'date-fns';

const api = create({
    baseURL: 'http://cdb46359ce6c.ngrok.io/api/v1',
    headers: {
        "access-control-allow-origin" : "*"
    },
})

const handleValidityToken = res => {
    
    const expires = parseISO(localStorage.getItem('EXPIRES_IN'))

    if(!(isBefore(new Date(), expires))) {
        console.log('data ok')

        const oldtoken = localStorage.getItem('TOKEN_KEY');
        const refresh = localStorage.getItem('REFRESH_TOKEN');

        const refreshToken = create({
            baseURL: 'http://127.0.0.1:3333/api/v1',
            headers: { Accept: 'application/vnd.github.v3+json' },
        })
       
        refreshToken
            .post('/admin/refresh', { refresh }, { headers: { Authorization: 'Bearer ' + oldtoken }})
            .then(res => {
                if(res.status) {
                    const newToken = res.data.token.token
                    const newRefreshToken = res.data.token.refreshToken
                    const newExpiresIn = res.data.expiresIn;

                    localStorage.setItem('TOKEN_KEY', newToken);
                    localStorage.setItem('REFRESH_TOKEN', newRefreshToken);
                    localStorage.setItem('EXPIRES_IN', newExpiresIn);
                }
            }).catch(err => {
                console.log(err);
            })

    }
}

api.addMonitor(handleValidityToken)

export default api;